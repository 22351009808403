import { Oval } from 'react-loader-spinner'
import React from 'react'

const Loader = () => {
  return (
    <div className="fixed inset-0 h-full w-full bg-transparent z-50 grid place-items-center">
      <div className="h-16 w-16 bg-secondary-d grid place-items-center rounded-md">
          <Oval
              height={30}
              width={30}
              color="#00414d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#00414d"
              strokeWidth={4}
              strokeWidthSecondary={4}
          />
      </div>
    </div>
  )
}

export default Loader
