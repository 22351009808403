import * as React from 'react';
import { createRoot } from 'react-dom/client';
import HomeView from './views/HomeView';
import './index.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import NoMatch from './views/NoMatch';
import RecoilizeDebugger from 'recoilize';
import 'react-dropdown-tree-select/dist/styles.css'
import { RecoilRoot } from 'recoil';

const basename = `${process.env.REACT_APP_ENV}` == 'production' ? '/include/metadata' : '';
const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <HomeView />
    },
    {
      path: '*',
      element: <NoMatch />
    }
  ],
  { basename }
);

createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RecoilRoot>
        <RecoilizeDebugger />
        <RouterProvider router={router} />
    </RecoilRoot>
  </React.StrictMode>
);
