export const customFieldsConfig = [
    {type:"number" , icon:"sicon-cell-signal" , text: "حقل رقمي"  , name:"",placeholder:"أدخل عنوان الحقل الرقمي"},
    {type:"date" , icon:"sicon-calendar" , text:"حقل تاريخ" , name:"",placeholder: "أدخل عنوان حقل التاريخ"},
    {type:"url" , icon:"sicon-link1" , text:"حقل رابط" , name:"",placeholder: "أدخل عنوان حقل الرابط"},
    {type:"text" , icon:"sicon-format-text" , text: "حقل نصي" , name:"",placeholder:"أدخل عنوان الحقل النصي"},
    {type:"file" , icon:"sicon-page-alt" , text:"حقل ملف" , name:"",placeholder: "أدخل عنوان حقل الملف"},
]

export const tabsConfig = [
    {value:0 , text:"المنتجات", sectionsLimit:10,entity:"product"},
    {value:1 , text:"الطلبات",sectionsLimit: 5,entity:"order"}
]