import React, {FC} from 'react'

type Props = {
    id: string,
    children: React.ReactNode
}
const Label: FC<Props> = ({id, children}) => {
    return (
        <label htmlFor={id ? id : ''}
               className="flex justify-start text-dark-300 font-medium text-xs text-start mb-1 gap-x-2">
            {children}
        </label>
    )
}
export default Label
