import React, { FC, useState } from 'react';
import './AlertBox.scss';

const AlertBox: FC<{
  textContent: string;
  dismissible: boolean;
  icon?: string;
  variant: 'info' | 'danger' | 'success' | 'warning';
  hasLink?: boolean;
  className?: string;
  linkUrl?: string;
}> = ({ textContent, variant, dismissible, icon, hasLink, className, linkUrl }) => {
  const [show, setShow] = useState<boolean>(true);
  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (linkUrl) {
      window.parent.location.href = linkUrl;
    }
  };
  if (!show) return null;
  return (
    <>
      <section className={`alert-box alert-box--${variant} ${className}`} role="alert">
        <div className="alert-box__content">
          <i className={`${icon} text-2xl`}></i>
          <article>{textContent}</article>
        </div>
        {dismissible && (
          <button onClick={() => setShow(false)}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        )}
        {hasLink && (
          <a
            onClick={handleLinkClick}
            href={linkUrl}
            className="rounded-md bg-warning-600 px-5 py-1.5 leading-6 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            ترقية
          </a>
        )}
      </section>
    </>
  );
};

export default AlertBox;
