import React from 'react';
import { Link } from 'react-router-dom';

import { baseUrlAtom } from '../store/atoms';
import {useRecoilValue} from "recoil";

const NoMatch = () => {
    const dashboardUrl = useRecoilValue(baseUrlAtom);

    return (
        <div className="flex flex-col items-center md:items-start justify-center h-screen px-12">
            <div className="flex flex-col space-y-4 space-x-4 justify-center items-center md:flex-row mb-4">
                <img
                    className="w-32 h-24 md:mr-4"
                    src="https://cdn.assets.salla.network/dash/vendor/salla/errors/images/error.svg"
                    alt="salla logo"
                />
                <h1 className="text-8xl font-bold  text-primary">404</h1>
            </div>
            <p className="text-4xl mb-8 text-gray-400">نأسف، الرابط غير موجود</p>
            <p className="text-2xl mb-2 text-gray-500">الرابط المطلوب غير موجود،</p>
            <p className="text-2xl mb-8 text-gray-500">
                من فضلك حاول مرة آخرى أو تواصل مع الدعم الفني بسلة
            </p>
            <Link to={dashboardUrl} className="text-primary-l font-normal text-xl py-2 px-4 rounded">
                الذهاب للصفحة الرئيسية
            </Link>
        </div>
    );
};

export default NoMatch;
