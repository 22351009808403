import { atom } from 'recoil';
import { custom_field, section } from '../types/section';
import { AppError } from '../types/error';
import { SweetAlertIcon } from 'sweetalert2';

export const defaultActiveSection = {
  id: -1 as number,
  name: '' as string,
  visible: false as boolean,
  conditions:{
    groups: null,
  },
  fields: [] as custom_field[],
};

export const defaultError = {
  type: 'error' as SweetAlertIcon,
  message: null
};
const sectionsAtom = atom({
  key: 'customSections',
  default: {
    product: [] as section[],
    order: [] as section[],
  }
});

const loadingAtom = atom({
  key: 'loading',
  default: false
});

const activeSectionAtom = atom<section>({
  key: 'activeSection',
  default: defaultActiveSection
});

const categoriesAtom = atom({
  key: 'categories',
  default: []
});

const errorAtom = atom<AppError>({
  key: 'error',
  default: defaultError
});

const showModalAtom = atom({
  key: 'showModal',
  default: false
});

const packageActive = atom({
  key: 'sharedStateEnabled', 
  default: true, 
});

const baseUrlAtom = atom<string>({
    key: 'baseUrl',
    default: ''
});

export { sectionsAtom, activeSectionAtom, categoriesAtom, errorAtom, showModalAtom, loadingAtom, packageActive, baseUrlAtom};
