import React, {FC} from 'react'

type Props = {
    children: React.ReactNode
}
const InputTitle:FC<Props> = ({children}) => {
    return (
        <p className="text-dark-100 text-xxs font-normal text-start pb-2">
            {children}
        </p>
    )
}
export default InputTitle
