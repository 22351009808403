import React, {FC} from 'react'

type Props = {
    icon : string
    cb : (e:React.ChangeEvent<HTMLInputElement>) => void
    value : string
    placeholder? : string
    register: any
    errors: []
    itemid: string | number
}
const InputGroup:FC<Props> = ({icon, cb , value,placeholder, register, itemid, errors}) => {

    return (
        <div className="relative rounded-md shadow-sm  flex-1">
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-3">
                <span className={`${icon} h-5 w-5 text-s-gray-500`} aria-hidden="true" />
            </div>
            <input
                type="text"
                value={value}
                onInput={cb}
                ref={register} 
                className={`${
                    errors[`customInput${itemid}`]?.message
                      ? 'border-danger-600 focus:border-danger-600 focus:ring-danger-600'
                      : 'border-s-gray-300 focus:border-s-gray-300 focus:ring-s-gray-300'
                  } block border w-full rounded-md py-2 pl-10 text-gray-900 dark:text-gray-500 ring-1 ring-inset ring-s-gray-300 placeholder:text-gray-400  focus:outline-none sm:text-sm sm:leading-6 dark:bg-dark-700 dark:border-dark-100 text-xs placeholder:text-xs`}
                placeholder={placeholder}
                {...register(`customInput${itemid}`,{
                    required: 'حقل العنوان مطلوب'
                  })}
            />
         
        </div>
    )
}
export default InputGroup
