import {Category} from "./types/category";

export const objectToQueryString = (obj: { [key: string]: string | string[] }) => {
  const keyValuePairs: string[] = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (Array.isArray(value)) {
        value.forEach(item => {
          keyValuePairs.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`);
        });
      } else {
        keyValuePairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
  }

  return keyValuePairs.join('&');
};

export const replaceItemAtIndex = (arr, index, newValue) => {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
};

export const transformData = (data: Category[], selectedCategories: string[] | null): Category[] => {
  return data.map(item => {
    const newItem = { ...item };
    if (newItem.sub_categories) {
      newItem.children = transformData(newItem.sub_categories, selectedCategories);
    }
    if (selectedCategories?.includes(newItem.value as string)) {
      newItem.checked = true;
    }
    return { ...newItem ,name: newItem.label};
  });
}