import React from 'react'

type InlineDeleteProps = {
    onConfirm: () => void;
    onCancel: () => void;
};

const InlineDelete: React.FC<InlineDeleteProps> = ({ onConfirm, onCancel }) => {
    return (
        <div className="flex absolute left-0 bg-white z-50 top-0 justify-center gap-x-2 items-center h-full px-4 py-4">
            <div className="text-dark-300 text-base">هل تريد تأكيد حذف القسم؟</div>
            <button
                className="bg-danger-600 hover:bg-red-600 text-white font-medium text-xs py-1 px-2 rounded"
                onClick={onConfirm}
            >
                نعم تأكيد
            </button>
            <button
                className="bg-white text-dark-200 font-medium text-xs py-1 px-1.5 border border-s-gray-300 rounded"
                onClick={onCancel}
            >
                إلغاء
            </button>
        </div>
    )
}
export default InlineDelete