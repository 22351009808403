import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { activeSectionAtom, categoriesAtom } from '../store/atoms';
import { RadioInput, Treeselect } from './lib';
import { transformData } from '../utils';
import {groupsSelector} from "../store/selectors";

const categoryOptions = [
  {
    id: 'all',
    name: 'all',
    value: 'true',
    label: 'كل المنتجات'
  },
  {
    id: 'selected',
    name: 'selected',
    value: 'false',
    label: 'تصنيفات مختاره',
  }
];
const CategorySelection = () => {
  const [section, setSection] = useRecoilState(activeSectionAtom);
  const groups = useRecoilValue(groupsSelector)
  const [value, setValue] = React.useState(groups ? 'false' : 'true');
  const categories = useRecoilValue(categoriesAtom);

  const transformedData = transformData(categories, groups || null);
  if (groups && groups.length > 0) {
    transformedData.forEach(dataItem => {
      groups?.forEach(selectedCategory => {
        if (dataItem.value === selectedCategory) {
          dataItem.checked = true;
        }
      });
    });
  }

  React.useEffect(() => {
    console.log(value,'value')
    console.log(groups,'groups')
    if(value == 'true'){
      setSection({ ...section, conditions: { ...section.conditions, groups: null } });
    }

  }, [value]);

  const handleChange = (selectedNodes) => {
    console.log(selectedNodes,'selectedNodes')
    setSection({ ...section, conditions: { ...section.conditions, groups: selectedNodes } });
  };
  return (
    <div className="w-full flex flex-col gap-y-2 ">
      <RadioInput
        isVertical
        value={value}
        setValue={setValue}
        options={categoryOptions}
        title="تخصيص المنتجات"
        subtitle="حدد ما إذا كنت تود تطبيق القسم لكل المنتجات أو لتصنيفات مختارة"
      />
      {value == 'false' && (
        <Treeselect
            onChange={handleChange}
            data={transformedData}
            selectedValues={groups ? groups.map(Number) : null || []}
        />
      )}
    </div>
  );
};

export default CategorySelection;
