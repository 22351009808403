import React, { useEffect, Fragment } from 'react';
import { useMessaging } from '../hooks/useMessaging';
import { useErrorAlert } from '../hooks/useErrorAlert';
import { useRecoilState } from 'recoil';
import { categoriesAtom, loadingAtom, sectionsAtom, packageActive } from '../store/atoms';

import AddFieldModal from '../components/AddFieldModal';
import api from '../services/api';
import BasicTable from '../components/UI/BasicTable';
import Loader from '../components/UI/Loader';
import { AlertBox, EmptyState, Panel } from '../components/lib';
import { useSignal } from '@preact/signals-react';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { activeTab } from '../store/signals';
import { tabsConfig } from '../config/customFields';
import { useIframeContent } from '../hooks/useIframeContent';

function HomeView() {

  const { isLoading, isToken, isAllowed } = useMessaging();
  const [, setLoading] = useRecoilState(loadingAtom);
  const isFetching = useSignal(true);
  const [PackageActiveState, setPackageActiveState] = useRecoilState(packageActive);

  useErrorAlert();
  const fetchSections = async () => {
    setLoading(true);

    isFetching.value = true;
    try {
      const { data } = await api.get('/metadata');
      const productSections = data.data.filter(
        (section: { entity: string }) => section.entity === 'product'
      );
      const orderSections = data.data.filter(
        (section: { entity: string }) => section.entity === 'order'
      );
      setCustomSections(
        data.success
          ? {
              product: productSections,
              order: orderSections
            }
          : {
              product: [],
              order: []
            }
      );
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    isFetching.value = false;
  };
  const fetchCategories = async () => {
    setLoading(true);

    isFetching.value = true;
    try {
      const { data } = await api.get('/products/categories/filters');
      setCategories(data?.data?.filters?.categories?.options || []);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    isFetching.value = false;
  };
  const fetchEnabledStatus = async () => {
    setLoading(true);
    isFetching.value = true;
    try {
      const { data } = await api.post(
        '/checking/metadata',
        {},
        {
          baseURL: process.env.REACT_APP_DASHBOARD_API_URL
        }
      );
      if(data?.data?.is_active !== undefined){
        setPackageActiveState(data?.data?.is_active);
      }
    } catch (e) {
      console.log(e,'e')
    }
    setLoading(false);
    isFetching.value = false;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading) {
        await fetchEnabledStatus();
        await fetchSections();
        await fetchCategories();
      }
    };
    fetchData();
  }, [isLoading, isToken]);

  useEffect(() => {
      if (!isLoading) {
        console.log({ PackageActiveState });
      }

    
  }, [PackageActiveState]);

  const [customSections, setCustomSections] = useRecoilState(sectionsAtom);
  const [, setCategories] = useRecoilState(categoriesAtom);
  if (isLoading) return <Loader />;
  if (!isAllowed) return null;
  return (
    <>
      <div className="p-2 sm:px-8 pb-6 mb-8">
        {!PackageActiveState && (
          <AlertBox
            textContent="هذه الميزة متوفرة فقط في باقة سلة سبيشال، سلة برو"
            dismissible={false}
            icon="sicon-info"
            variant={'warning'}
            hasLink={true}
            className="mb-8"
            linkUrl="https://s.salla.sa/marketplace"
          ></AlertBox>
        )}

        <Tab.Group
          selectedIndex={activeTab.value}
          onChange={index => {
            activeTab.value = index;
          }}
        >
          <Tab.List>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  className={classNames(
                    selected ? 'active-tab' : 'hover:bg-secondary-50 ',
                    'tab outline-0 inline-flex items-center justify-center transition-colors rounded-s-md border-e-0'
                  )}
                >
                  <span className="sicon-t-shirt me-2"></span>
                  المنتجات
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  className={classNames(
                    selected ? 'active-tab' : 'hover:bg-secondary-50 ',
                    'tab outline-0 inline-flex items-center justify-center transition-colors rounded-e-md'
                  )}
                >
                  <span className="sicon-inbox me-2"></span>
                  الطلبات
                </button>
              )}
            </Tab>
          </Tab.List>
          <AddFieldModal />
          <Tab.Panels>
            <Tab.Panel>
              <Panel
                title={`أقسام الحقول المخصصة (${customSections.product?.length}/${tabsConfig[0].sectionsLimit})`}
                titleIcon="sicon-list-add"
              >
                {customSections.product.length == 0 && !isFetching.value ? (
                  <EmptyState
                    title="أضف حقولًا مخصصة لمنتجاتك"
                    subtitle="تمكِّنك هذه الحقول من تنظيم عرض بيانات المنتج في صفحة المنتج"
                    icon="sicon-t-shirt"
                  />
                ) : (
                  <BasicTable data={customSections.product} />
                )}
              </Panel>
            </Tab.Panel>
            <Tab.Panel>
              <Panel
                title={`أقسام الحقول المخصصة (${customSections.order?.length}/${tabsConfig[1].sectionsLimit})`}
                titleIcon="sicon-list-add"
              >
                {customSections.order?.length == 0 && !isFetching.value ? (
                  <EmptyState
                    title="أضف حقولًا مخصصة للطلبات"
                    subtitle="تمكِّنك هذه الحقول من إضافة معلومات في صفحة الطلب دون مشاركتها مع العميل."
                    icon="sicon-inbox"
                  />
                ) : (
                  <BasicTable data={customSections.order} />
                )}
              </Panel>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
}

export default useIframeContent(HomeView);
