import React, { FC } from 'react';

type Props = {
  title: string;
  message: string;
  setVisible: (value: boolean) => void;
  position?: string;
};
const Tooltip:FC<Props> = ({title , message , setVisible,position}) => {
  return (
    <div className={`absolute ${position} text-start bg-teal-200 p-3 mx-auto rounded-lg shadow-md z-40 max-w-[205px]`} onClick={() => setVisible(false)}>
      <div className="text-xxxs font-bold text-primary-d">{title}</div>
      <p className="text-xxxs text-primaary-l mt-2">{message}</p>
      <div className="tooltip-arrow"></div>
    </div>
  );
};

export default Tooltip;
