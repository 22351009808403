import { Dialog, Transition } from '@headlessui/react';
import './Modal.scss';
import * as React from 'react';

type Props = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  saveModal: () => void;
  children: React.ReactNode;
  closeModal?: () => void;
  dialogTitle?: string;
  saveButtonText?: string;
  cancelButtonText?: string;
};

const Modal = ({
  showModal,
  setShowModal,
  saveModal,
  children,
  dialogTitle,
  closeModal,
  saveButtonText,
  cancelButtonText,
}: Props) => {
  return (
    <Transition.Root show={showModal} as={React.Fragment}>
      <Dialog as="div" className="modal" onClose={setShowModal}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div className="modal__backdrop" aria-hidden="true" />
        </Transition.Child>

        {/* Full-screen container to center the panel */}
        <div className="modal__container">
          {/* The actual dialog panel  */}
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="modal__body">
              <div>
                <div className="">
                  <Dialog.Title className="modal__header">
                    <span className="text-xl text-primary-l dark:text-gray-300">{dialogTitle}</span>
                    <span
                      className="sicon-cancel text-xl cursor-pointer"
                      onClick={() => setShowModal(false)}
                    ></span>
                  </Dialog.Title>
                  {/*  body  */}
                  {children}
                </div>
              </div>
              {/* actions */}
              <div className="modal__footer">
                <button
                  onClick={saveModal}
                  className="bg-secondary-50 border-secondary-50 text-primary-l px-4 py-2 rounded-md hover:bg-secondary transition-colors"
                >
                  {saveButtonText}
                </button>
                <button
                  className="bg-gray-200 text-dark-300 px-4 py-2 rounded-md hover:bg-gray-300 transition-colors"
                  onClick={() => {
                    setShowModal(false);
                    closeModal && closeModal();
                  }}
                >
                  {cancelButtonText}
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
