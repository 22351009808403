

import { selector } from 'recoil';
import {activeSectionAtom} from './atoms';



const activeSectionFieldLength = selector({
    key: 'activeSectionFieldLength',
    get: ({ get }) => {
        const activeSection = get(activeSectionAtom);
        return activeSection.fields.length;
    }
    });

const groupsSelector = selector({
  key: 'groups',
  get: ({ get }) => {
    const activeSection = get(activeSectionAtom);
    return activeSection.conditions.groups;
  }
});


export {  activeSectionFieldLength , groupsSelector};
