import React, { FC, useState } from 'react';
import Label from './UI/Label';
import { replaceItemAtIndex } from '../utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { activeSectionAtom } from '../store/atoms';
import { activeSectionFieldLength } from '../store/selectors';
import Tooltip from './UI/Tooltip';
import { Dropdown, InputGroup } from './lib';
import { ReactSortable } from 'react-sortablejs';
import { custom_field } from '../types/section';
import { customFieldsConfig } from '../config/customFields';
import {activeTab} from "../store/signals";

type Props = {
  customFields: custom_field[];
  setCustomFields: React.Dispatch<React.SetStateAction<custom_field[]>>;
  register: any; // Add register prop for react-hook-form registration
  errors: any; // Add error prop for displaying error message
};

const CustomFields: FC<Props> = ({ customFields, setCustomFields, register, errors }) => {
  const [section, setSection] = useRecoilState(activeSectionAtom);
  const [visible, setVisible] = useState(false);
  const fieldsLength = useRecoilValue(activeSectionFieldLength);

  const handleFieldAdd = item => {
    setSection(prevSection => ({
      ...prevSection,
      fields: [...prevSection.fields, { ...item, id: (fieldsLength + Math.random().toString(16).slice(2)).toString() }]
    }));
  };
  const handleFieldChange = (e, id) => {
    const index = section.fields.findIndex(field => field.id === id);

    setSection(prevSection => ({
      ...prevSection,
      fields: replaceItemAtIndex(prevSection.fields, index, {
        ...prevSection.fields[index],
        name: e.target.value,
        sort: index + 1
      })
    }));
  };

  const handleFieldDelete = id => {
    setSection(prevSection => ({
      ...prevSection,
      fields: prevSection.fields.filter(field => field.id !== id)
    }));
  };

  return (
    
    <div className="relative flex flex-col gap-y-2 items-start w-full" >
      <Label id="custom_felds">
        الحقول <span className='text-dark-100'>({`${fieldsLength ?? 0}/5`})</span>
      </Label>
      <p className="text-dark-100 text-xxs font-normal text-start pb-2">
        {activeTab.value == 0 ? "ستظهر الحقول المخصصة في بيانات المنتج لتتمكن لاحقا من إدخال قيمة كل حقل" : "ستظهر الحقول المخصصة في صفحة الطلب لك فقط ولن تكون ظاهرة للعميل" }
      </p>

      {fieldsLength == 0 && activeTab.value == 1 && (
          <div
              className="flex items-center justify-start gap-x-2 text-danger-600 text-xxxs font-normal py-1"
              role="alert"
          >
            <span className="sicon-info"></span>
            <p>يجب إضافة حقل واحد على الأقل للحفظ</p>
          </div>
      )}
      <div
        onMouseEnter={() => setVisible(fieldsLength == 5)}
        onMouseLeave={() => setVisible(false)}
        className="relative w-full flex items-start"
      >
        <Dropdown
          options={customFieldsConfig}
          disabled={fieldsLength == 5}
          DropdownButton={() => DropdownButton({ disabled: fieldsLength == 5 })}
          handleClick={handleFieldAdd}
        />
        {visible && (
          <Tooltip
            position="right-36 -top-5"
            setVisible={setVisible}
            title="الحد الأقصى لعدد الحقول هو 5"
            message="يجب حذف حقل للتمكن من إضافة حقل جديد"
          />
        )}
      </div>

      {fieldsLength > 0 && (
        <ReactSortable
          list={customFields}
          setList={setCustomFields}
          animation={150}
          sort={true}
          direction={'vertical'}
          className="sortable-list flex flex-col gap-y-4 items-start justify-center w-full py-4 "
          handle={'.handle'}
        >
          {customFields.map(field => (
            <div className="flex gap-x-3 items-center w-full" key={field.id}>
              <div className=" p-1 handle">
                <span className="sicon-menu h-5 w-5 text-dark-100"></span>
              </div>
              <InputGroup
                icon={field.icon}
                cb={e => handleFieldChange(e, field.id)}
                value={field.name}
                placeholder={field.placeholder}
                register={register}
                itemid={field.id}
                errors={errors}
              />
              <div
                className="border border-danger-600 flex items-center justify-center h-8 w-8 rounded cursor-pointer"
                onClick={() => handleFieldDelete(field.id)}
              >
                <span className="sicon-trash-2 text-md text-center text-danger-600"></span>
              </div>
            </div>
          ))}
        </ReactSortable>
      )}
    </div>
  );
};
export default CustomFields;

const DropdownButton: FC<{ disabled: boolean }> = ({ disabled }) => {
  return (
    <button
      type="button"
      className={` ${
        disabled ? 'opacity-55' : ''
      } flex items-center justify-center px-3 py-2 dark:text-secondary-d gap-x-2 border-secondary-l border rounded-md transition-colors`}
    >
      <span
        className={`sicon-add ${
          disabled ? 'opacity-55' : ''
        } dark:text-secondary-d text-sm text-primary-l font-medium`}
      ></span>
      <span
        className={`${
          disabled ? 'opacity-55' : ''
        } text-xs text-primary-l font-medium`}
      >
        إضافة حقل
      </span>
    </button>
  );
};
