import {Menu, Transition} from '@headlessui/react'
import classNames from 'classnames'
import * as React from 'react'
import './Dropdown.scss'

type Props = {
    options: DropdownOption[]
    DropdownButton?: () => React.ReactNode
    handleClick : (option:DropdownOption) => void
    disabled? : boolean
    left?: boolean

}

type DropdownOption = {
    text: string
    icon: string
    color?: string
    hoverColor?: string
    action? : string
}

const DropdownItem = ({option, onClick}: { option: DropdownOption; onClick: (option:DropdownOption) => void }) => {
    return (
        <Menu.Item>
            {({active}) => (
                <span
                    onClick={() => onClick(option)}
                    className={classNames({
                        'bg-gray-100 text-gray-900 dark:bg-dark-300 dark:text-gray-400': active,
                        'text-gray-700 ': !active,
                        'group flex items-center px-4 py-2 text-sm cursor-pointer': true
                    })}
                >
          <span
              className={classNames(
                  option.color ?? 'text-dark-400',
                  option.hoverColor ?? 'group-hover:text-dark-500'
              )}
          >
            <span className={`${option.icon} mr-3 h-5 w-5`} aria-hidden="true"/>
            <span className="text-xxxs">{option.text}</span>
          </span>
        </span>
            )}
        </Menu.Item>
    )
}
const Dropdown = ({options, DropdownButton , handleClick , disabled , left = true}: Props ) => {
    return (
        <Menu as="div" className="dropdown">
            <div>
                <Menu.Button disabled={disabled} className={`${DropdownButton ? '' : 'dropdown__button'}`}>
                    {DropdownButton ? DropdownButton() :
                        <span
                            className="-mr-1 h-5 w-5 text-gray-400 sicon-ellipsis-filled"
                            aria-hidden="true"
                        ></span>
                    }
                </Menu.Button>
            </div>

            <Transition
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className={`dropdown__items ${left ? 'right-0 origin-top-right' : 'left-0 origin-top-left'}`}>
                    <div className="">
                        {options.map((option, index) => (
                            <DropdownItem key={index} option={option} onClick={(option) => handleClick(option)}/>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default Dropdown
