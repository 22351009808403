import { useEffect } from 'react';
import Swal from 'sweetalert2';
import {useRecoilValue} from "recoil";
import {errorAtom} from "../store/atoms";


export const useErrorAlert = () => {
    const error = useRecoilValue(errorAtom);

    useEffect(() => {
        if (error.message) {
            Swal.fire({
                icon: error.type ,
                html: error.message,
                confirmButtonText: 'موافق',
                confirmButtonColor: '#76E8CD',
                timer: 3000,
            });
        }
    }, [error]);
};
