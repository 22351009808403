import React, { useState, useEffect } from 'react';

export const useIframeContent = (WrappedComponent:React.ComponentType) => {
  const IframeComponent = () => {
    const [isInIframe, setIsInIframe] = useState(false);

    useEffect(() => {
      if (process.env.NODE_ENV === 'development') {
        setIsInIframe(true);
      } else {
        setIsInIframe(window.self !== window.top);
      }
    }, []);

    if (!isInIframe)
      return (
        <div className="flex justify-center items-center h-screen flex-col">
          <h1 className="text-5xl mb-5">Oops!</h1>
          <p className="text-lg text-center">This app is meant to be displayed within an iframe</p>
        </div>
      );

    return <WrappedComponent />;
  };

  IframeComponent.displayName = `WithIframe(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return IframeComponent;
};
