import React, { FC, useEffect, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import Label from './UI/Label';
import InputTitle from './UI/InputTitle';
import { useRecoilState } from 'recoil';
import {
  activeSectionAtom,
  defaultActiveSection,
  defaultError,
  errorAtom,
  loadingAtom,
  sectionsAtom,
  showModalAtom,
  packageActive
} from '../store/atoms';

import CustomFields from './CustomFields';
import CategorySelection from './CategorySelection';
import api from '../services/api';
import { AddButton, Modal } from './lib';
import Loader from './UI/Loader';
import Swal from 'sweetalert2';
import { custom_field } from '../types/section';
import { activeTab } from '../store/signals';
import { tabsConfig } from '../config/customFields';

const AddFieldModal: FC = () => {
  const [packageActiveState] = useRecoilState(packageActive);
  const [showModal, setShowModal] = useRecoilState(showModalAtom);
  const [section, setSection] = useRecoilState(activeSectionAtom);
  const [sections, setSections] = useRecoilState(sectionsAtom);
  const [, setError] = useRecoilState(errorAtom);
  const [loading, setLoading] = useRecoilState(loadingAtom);
  const [customFields, setCustomFields] = useState<custom_field[]>([]);
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    clearErrors
  } = useForm();

  useEffect(() => {
    setValue('name', section.id != -1 ? section.name : '');
    setValue('visible', section.id != -1 ? section.visible : false);
    clearErrors();
  }, [showModal]);

  const handleSuccess = updatedSections => {
    setShowModal(false);
    setSections({ ...sections, [tabsConfig[activeTab.value].entity]: updatedSections });
    setSection(defaultActiveSection);
    const message = section.id == -1 ? 'تمت إضافة القسم بنجاح' : 'تم تعديل القسم بنجاح';
    Swal.fire({
      icon: 'success',
      title: message,
      html:
        activeTab.value == 0
          ? 'يمكنك إستعراض الحقول المخصصة في بيانات المنتجات التي خصصتها'
          : 'يمكنك إستعراض الحقول المخصصة في صفحة الطلب',
      confirmButtonText: 'موافق',
      confirmButtonColor: '#76E8CD',
      timer: 5000,
      customClass: {
        title: 'swal-title',
        htmlContainer: 'swal-html-container'
      }
    });
  };
  const redirectToDashboard = (url: string) => {
    window.parent.location.href = url;
  };

  const handlePormotePackage = () => {
    Swal.fire({
      html: `
              <div class="mt-8 w-full px-6">
              <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mx-auto"
              >
                <path
                  d="M24.9814 15.6162L27.7995 17.2376C28.3156 17.5347 28.9471 17.0955 28.8113 16.5399L28.0643 13.491L30.5565 11.4369C31.0115 11.0623 30.767 10.3517 30.1695 10.3065L26.8895 10.0417L25.6061 7.16073C25.3752 6.63751 24.5875 6.63751 24.3566 7.16073L23.0732 10.0352L19.7933 10.3C19.1957 10.3453 18.9512 11.0558 19.4062 11.4305L21.8984 13.4846L21.1514 16.5335C21.0156 17.089 21.6471 17.5282 22.1632 17.2311L24.9814 15.6162Z"
                  fill="#FFAF44"
                />
                <path
                  d="M40 14.7329L44.4657 17.1883C45.2835 17.6383 46.2842 16.9731 46.069 16.1318L44.8854 11.5145L48.8345 8.40364C49.5555 7.83626 49.1681 6.76019 48.2212 6.69171L43.0238 6.29063L40.99 1.92766C40.6241 1.13528 39.3759 1.13528 39.01 1.92766L36.9762 6.28085L31.7788 6.68193C30.8319 6.75041 30.4445 7.82648 31.1655 8.39386L35.1146 11.5047L33.931 16.122C33.7158 16.9633 34.7165 17.6285 35.5343 17.1785L40 14.7329Z"
                  fill="#FFAF44"
                />
                <path
                  d="M55.0187 15.6162L57.8368 17.2376C58.3529 17.5347 58.9844 17.0955 58.8486 16.5399L58.1016 13.491L60.5938 11.4369C61.0488 11.0623 60.8043 10.3517 60.2067 10.3065L56.9268 10.0417L55.6434 7.16073C55.4125 6.63751 54.6248 6.63751 54.3939 7.16073L53.1105 10.0352L49.8306 10.3C49.233 10.3453 48.9885 11.0558 49.4435 11.4305L51.9357 13.4846L51.1887 16.5335C51.0529 17.089 51.6844 17.5282 52.2005 17.2311L55.0187 15.6162Z"
                  fill="#FFAF44"
                />
                <path
                  d="M73.2302 62.0677L69.9764 34.4013C69.1012 26.9525 62.8935 21.3334 55.537 21.3334H24.4598C17.1033 21.3334 10.8956 26.9525 10.0204 34.4013L6.76998 62.0677C6.27541 66.2757 7.57217 70.5081 10.3314 73.6777C13.0872 76.85 17.0531 78.6667 21.2094 78.6667H58.7908C62.9437 78.6667 66.9096 76.85 69.6688 73.6777C72.4246 70.5089 73.7248 66.2766 73.2302 62.0677ZM64.3169 68.8699C62.84 70.567 60.8022 71.5005 58.5809 71.5005H21.4201C19.1954 71.5005 17.1568 70.567 15.6841 68.8699C14.2072 67.1728 13.5427 64.999 13.8061 62.7464L17.02 35.3902C17.4814 31.4647 20.7548 28.4996 24.634 28.4996H55.3637C59.2421 28.4996 62.5162 31.4639 62.9777 35.3902L66.1949 62.7464C66.4584 64.999 65.7896 67.1728 64.3169 68.8699Z"
                  fill="#004D5A"
                />
                <path
                  d="M58.6709 53.5755C58.1594 52.7755 57.3733 52.2269 56.4573 52.0301C55.5395 51.8334 54.6048 52.0128 53.8204 52.5337C44.4635 58.7578 35.3573 58.7569 25.9826 52.5329C25.1982 52.0119 24.2609 51.8325 23.3457 52.031C22.4296 52.2286 21.6444 52.7781 21.1337 53.5781C20.623 54.3782 20.448 55.3333 20.6417 56.2677C20.8355 57.202 21.3742 58.0029 22.1586 58.5238C28.0169 62.4137 33.9892 64.3856 39.9096 64.3856C45.8308 64.3856 51.7988 62.4129 57.6487 58.5221C58.433 58.0003 58.9709 57.1985 59.1638 56.2642C59.3576 55.3307 59.1825 54.3755 58.6709 53.5755Z"
                  fill="#004D5A"
                />
              </svg>
        
            <h2 class="mt-5  text-dark-300 font-bold text-[24px] text-center mb-1 w-full leading-10">
              الميزة هذه متوفرة فقط في باقة سلة سبيشال، سلة برو
            </h2>
            <p class="text-dark-300 text-xl font-normal text-center leading-9">
              يمكنك إضافة حقول مخصصة في صفحة الطلب والمنتج بناءً على احتياجاتك، مع إمكانية جعل بعضها
              مرئيًا للعملاء وبعضها لك فقط في لوحة التحكم.
            </p>
         </div>
            `,
      confirmButtonText: 'ترقية الباقة',
      showCancelButton: true,
      cancelButtonText: 'إلغاء',
      buttonsStyling: false,
      customClass: {
        actions: 'flex w-full gap-x-4 px-10 pb-6', // Add spacing between buttons
        confirmButton: 'flex-1 bg-secondary-50 text-primary-d font-medium py-2 px-4 rounded',
        cancelButton: 'flex-1 bg-s-gray-300 text-dark-200 font-medium py-2 px-4 rounded'
      },
      preConfirm: () => {
        redirectToDashboard('https://s.salla.sa/marketplace');
      }
    });
  };
  const handleError = message => {
    setError({
      type: 'error',
      message:
        '<h2 class="font-bold text-2xl">تنبيه<h2> <p class="mt-1">' + message + '</p>' || 'حدث خطأ ما'
    });
  };

  const onSubmit = async data => {
    const { fields } = { fields: data };
    customFields.forEach((item, index) => {
      item.sort = index + 1;
    });
    const updatedSection = {
      ...section,
      ...fields,
      fields: customFields,
      entity: tabsConfig[activeTab.value].entity
    };

    setSection(updatedSection);
    setError(defaultError);
    setLoading(true);
    const { data: res } = await (section.id != -1
      ? api.put(`/${section.id}`, updatedSection)
      : api.post('/', updatedSection));

    if (res.success) {
      handleSuccess(res.data);
    } else {
      const errorMessage = Array.isArray(res.data.message) ? res.data.message[0] : 'حدث خطأ ما';
      handleError(errorMessage);
    }

    setLoading(false);
  };

  const AddField = () => {
    const { entity, sectionsLimit } = tabsConfig[activeTab.value];
    const currentSectionsLength = sections[entity].length;

    if (currentSectionsLength === sectionsLimit) {
      setError({
        type: 'warning',
        message: `<h2 class="font-bold text-2xl">بلغت الحد الأقصى لعدد الأقسام (${sectionsLimit})</h2>
        <p class="mt-2">يمكنك حذف أحد الأقسام لإضافة قسم جديد.</p>`
      });
    } else {
      setSection(defaultActiveSection);
      setShowModal(true);
    }
  };

  useEffect(() => {
    const originalList = [...section.fields];
    const newList = JSON.parse(JSON.stringify(originalList)); // This creates a deep copy of the list
    newList.forEach((item, index) => {
      item.sort = index + 1;
    });

    setCustomFields(newList);
  }, [section]);

  return (
    <div className={'mt-3'}>
        <AddButton
          onClick={packageActiveState ? AddField : handlePormotePackage}
          className={loading ? 'opacity-50 pointer-events-none' : ''}
        >
          إضافة قسم جديد
        </AddButton>

      <Modal
        saveModal={handleSubmit(onSubmit)}
        showModal={showModal}
        setShowModal={setShowModal}
        dialogTitle={`${section.id != -1 ? section.name : 'إضافة قسم'}`}
        saveButtonText={section.id != -1 ? 'حفظ' : 'إضافة'}
        cancelButtonText="إلغاء"
      >
        {loading && <Loader />}
        <form
          className="py-4 px-4 flex flex-col items-start gap-y-3 w-full max-h-full bg-white"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col space-y-1 items-start w-full">
            <Label id="name">عنوان القسم</Label>
            <InputTitle>أضف عنوانًا مناسبًا للقسم الذي ستضيف به الحقول.</InputTitle>
            <input
              className={`${
                errors?.name?.message
                  ? 'border-danger-600 focus:border-danger-600 focus:ring-danger-600'
                  : 'border-s-gray-300 focus:border-s-gray-300 focus:ring-s-gray-300 '
              } border py-2 px-4 w-full focus:outline-none rounded text-gray-700 dark:text-gray-500 placeholder:text-dark-100 placeholder:text-xs text-xs dark:bg-dark-700 dark:border-dark-100`}
              placeholder="أدخل عنوان القسم"
              id="name"
              {...register('name', {
                required: 'حقل العنوان مطلوب'
              })}
            />
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => <p className="text-xxs text-danger-600 py-1">{message}</p>}
            />
          </div>

          {activeTab.value == 0 && (
            <div className="flex flex-col items-start w-full">
              <CategorySelection />
            </div>
          )}

          <CustomFields
            customFields={customFields}
            setCustomFields={setCustomFields}
            register={register}
            errors={errors}
          />

          {activeTab.value == 0 && (
            <div className="flex flex-col space-y-1 items-start">
              <Label id="">خيارات العرض</Label>
              <div className="flex items-center justify-start gap-x-3">
                <input
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-primary-l focus:ring-primary-l "
                  id="visible"
                  {...register('visible')}
                />
                <label className="text-xs text-dark-300 dark:text-gray-300" htmlFor="visible">
                  عرض القسم للعملاء في صفحة المنتج
                </label>
              </div>
            </div>
          )}
        </form>
      </Modal>
    </div>
  );
};
export default AddFieldModal;
