import React, { FC, Fragment, useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { activeSectionAtom, sectionsAtom, showModalAtom, packageActive } from '../../store/atoms';
import { Dropdown } from '../lib';
import InlineDelete from './InlineDelete';
import api from '../../services/api';
import { activeTab } from '../../store/signals';
import { tabsConfig } from '../../config/customFields';
type Props = {
  data: any;
  emptyMessage?: string;
};



const BasicTable: FC<Props> = ({ data, emptyMessage }) => {
  const [packageActiveState] = useRecoilState(packageActive);
  const [, setShowModal] = useRecoilState(showModalAtom);
  const [, setSection] = useRecoilState(activeSectionAtom);
  const [sections, setSections] = useRecoilState(sectionsAtom);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [deleteTimeout, setDeleteTimeout] = useState(null);

  const dropdownOptions = [
    ...(packageActiveState ? [{
      text: 'تعديل',
      icon: 'sicon-edit',
      color: 'dark:text-dark-100',
      hoverColor: 'text-dark-500',
      action: 'edit'
    }] : []),
    {
      text: 'حذف',
      icon: 'sicon-trash',
      color: 'text-red-500',
      hoverColor: 'text-red-600',
      action: 'delete'
    }
  ];

  const handleSection = (e, item) => {
    if (e.action == 'edit') {
      setSection(item);
      setShowModal(true);
      return;
    }
    if (e.action == 'delete') {
      setSelectedRowId(item.id);
      return;
    }
  };

  const handleConfirmDelete = async () => {
    const { data: res } = await api.delete(`/${selectedRowId}`);
    if (res.success) {
      const newSections = sections[tabsConfig[activeTab.value].entity].filter(
        item => item.id != selectedRowId
      );
      setShowDeleteSuccess(true);
      const timeoutId = setTimeout(() => {
        setSections({ ...sections, [tabsConfig[activeTab.value].entity]: newSections });
        setShowDeleteSuccess(false);
        setSelectedRowId(null);
      }, 1500);
      setDeleteTimeout(timeoutId as any);
    }
  };

  useEffect(() => {
    return () => {
      if (deleteTimeout) {
        clearTimeout(deleteTimeout);
      }
    };
  }, [deleteTimeout]);
  const handleCancelDelete = () => {
    setSelectedRowId(null);
  };

  return (
    <>
      {data?.length == 0 && !showDeleteSuccess && emptyMessage && (
        <div className="whitespace-nowrap ps-2 py-8 text-sm text-gray-500 flex w-full pe-4">
          <div className="flex-1 flex items-center justify-end">
            <div className="flex flex-col items-center justify-center">
              <div className="text-dark-200 text-sm">{emptyMessage}</div>
            </div>
          </div>
        </div>
      )}
      <div className=" flow-root">
        <div className="">
          <div className="inline-block min-w-full pb-2 align-middle ">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-s-gray-200 dark:bg-dark-600 border-b-dark-100">
                <tr className="">
                  <th
                    scope="col"
                    className="whitespace-nowrap py-2.5 pe-2 ps-5 text-base text-start font-normal text-dark-300 dark:text-dark-200"
                  >
                    عنوان القسم
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap hidden sm:table-cell py-2.5 px-2 text-base font-normal text-center text-dark-300 dark:text-dark-200"
                  >
                    عدد الحقول
                  </th>
                  {activeTab.value == 0 && (
                    <th
                      scope="col"
                      className="whitespace-nowrap hidden sm:table-cell py-2.5 px-2 text-base font-normal text-center text-dark-300 dark:text-dark-200"
                    >
                      عرض للعملاء في صفحة المنتج
                    </th>
                  )}
                  <th
                    scope="col"
                    className=" py-2.5 ps-2 pe-5 text-base font-normal text-center text-dark-300 dark:text-dark-200"
                  >
                    خيارات
                  </th>
                </tr>
              </thead>
              <tbody
                className={`divide-y divide-gray-200 bg-white dark:bg-dark-500`}
              >
                {data &&
                  data.length > 0 &&
                  data.map(item => (
                    <Fragment key={item.id}>
                      {showDeleteSuccess && selectedRowId == item.id ? (
                        <tr>
                          <td className="whitespace-nowrap px-2 ps-5 py-4 font-medium text-base text-dark-300 hover:text-primary-l cursor-pointer">
                            <span className="sicon-check rounded-full font-medium text-green-600 border-2 border-green-600 p-0.5 mr-2"></span>
                            تم الحذف بنجاح
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td
                            onClick={() => {
                              if (packageActiveState) {
                                setSection(item);
                                setShowModal(true);
                              }
                            }}
                            className={`px-2 ps-5 py-4 font-medium text-dark-200 hover:text-primary-l text-base cursor-pointer max-width-[200px] ${packageActiveState ? '' : 'pointer-events-none opacity-50'}`}
                          >
                            {item.name}
                          </td>
                          <td className={`whitespace-nowrap hidden sm:table-cell px-2 py-4 text-sm text-dark-300 dark:txxt-dark-200 text-center ${packageActiveState ? '' : 'opacity-50'}`}>
                            <div>{item.fields.length}</div>
                          </td>
                          {activeTab.value == 0 && (
                            <td className={`whitespace-nowrap hidden sm:table-cell px-2 py-4 text-sm text-primary-l dark:text-secondary-d text-center ${packageActiveState ? '' : 'opacity-50'}`}>
                              {item.visible ? (
                                <span className="sicon-check"></span>
                              ) : (
                                <span className="sicon-cancel"></span>
                              )}
                            </td>
                          )}
                          <td className="whitespace-nowrap ps-2 pe-5 py-4 text-base font-normal text-primary-l text-center relative">
                            {selectedRowId !== item.id && (
                              <Dropdown
                                options={dropdownOptions}
                                left={false}
                                handleClick={e => handleSection(e, item)}
                              />
                            )}
                            {selectedRowId === item.id && (
                              <InlineDelete
                                onConfirm={handleConfirmDelete}
                                onCancel={handleCancelDelete}
                              />
                            )}
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicTable;
