import axios from 'axios';
// import { getToken } from './authService';

const CfClientId = process.env.REACT_APP_CF_ID;
const CfClientSecret = process.env.REACT_APP_CF_SECRET;

const appEnv = `${process.env.REACT_APP_ENV}`;
const target = appEnv === 'production' ? window.parent.origin : '*';

let RequestHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-SALLA-SERVICE': 'metadata',
};

if (CfClientId && CfClientSecret) {
    RequestHeaders['CF-Access-Client-Id'] = CfClientId;
    RequestHeaders['CF-Access-Client-Secret'] = CfClientSecret;
}

const axiosInstance = axios.create({
    headers: RequestHeaders
});

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        console.log('error', error);
        if (error.response?.status === 401) {
            console.log('authentication error');
            window.parent.postMessage({ event: 'iframe.legacy.auth.refresh' }, target);
        }
        return error.response;
    }
);

export default axiosInstance;
