import React, { useEffect, useRef } from 'react';
import './TreeSelect.scss';
import { Category } from '../../../types/category';
import '@salla.sa/lit-components/dist/assets/index.js';

interface STreeSelect {
  selectedValues: number[];
  options: Category[];
  addEventListener: (event: string, callback: any) => void;
  removeEventListener: (event: string, callback: any) => void;
}

function TreeSelect({
  data,
  onChange,
  selectedValues
}: {
  data: Category[];
  onChange: (selectedOptions: number[]) => void;
  selectedValues: number[];
}) {
  const treeselectRef = useRef<STreeSelect | null>(null);

  useEffect(() => {
    const treeselectElement = treeselectRef.current;

    if (treeselectElement) {
      treeselectElement.selectedValues = selectedValues;
      treeselectElement.options = data;

      treeselectElement.addEventListener('selection-changed', (e: any) => {
        onChange(e.detail.selectedOptions);
      });
      return () => {
        treeselectElement.removeEventListener('selection-changed', onChange);
      };
    }
  }, []);

  return <s-treeselect ref={treeselectRef}></s-treeselect>;
}

export default TreeSelect;
