import api from '../services/api';
import { useEffect, useState } from 'react';

import { baseUrlAtom } from '../store/atoms';
import {useRecoilState} from "recoil";

export const useMessaging = () => {
  const trustedDomains = `${process.env.REACT_APP_TRUSTED_DOMAINS}`;
  const [isLoading, setIsLoading] = useState(true);
  const [isToken, setIsToken] = useState(false);
  const [isAllowed, setIsAllowed] = useState(true);
  const [, setBaseUrl] = useRecoilState(baseUrlAtom);
  const appEnv = `${process.env.REACT_APP_ENV}`;
  const target = appEnv === 'production' ? window.parent.origin : '*';
  const isAllowedOrigin = origin => {
    const hostname = new URL(origin).hostname;
    return trustedDomains?.split(',').some(domain => hostname.endsWith(domain));
  };

  useEffect(() => {
    const handleMessageFromParent = (event: MessageEvent) => {
      if (!isAllowedOrigin(event.origin)) {
        setIsLoading(false);
        setIsAllowed(false);
        console.error('domain NOT verified: ', event.origin);
        return;
      }

      if (typeof event.data !== 'object') {
        console.warn('The data is not object, skipped message handler');
        return;
      }

      if (event.data.event == 'iframe.loading') {
        api.defaults.headers.common['s-store-id'] = event.data['s-store-id'];
        setBaseUrl(event.data.baseUrl);
        api.defaults.baseURL = event.data.baseApiUrl;
        if (event.data.darkMode) {
          document.body.classList.add('dark');
        } else {
          document.body.classList.remove('dark');
        }
      }

      if (event.data.event == 'iframe.legacy.auth') {
        const { token } = event.data;
        if (token) {
          api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          setIsToken(true);
          setIsLoading(false);
        } else {
          delete api.defaults.headers.common['Authorization'];
        }
      }
    };

    const updateHeight = () => {
      window.parent.postMessage(
          { event: 'resize', value: document.documentElement.scrollHeight },
          target
      );
    };

    // Event listener for window resize
    window.addEventListener('resize', updateHeight);
    window.addEventListener('load', updateHeight);

    // Observe changes to the content and adjust height
    new MutationObserver(updateHeight).observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: true
    });

    window.addEventListener('message', handleMessageFromParent, false);

    // lets init our iframe
    window.parent.postMessage(
        { event: 'iframe.loading', height: document.documentElement.scrollHeight },
        target
    );
  }, []);

  return { isLoading, isToken, isAllowed };
};
