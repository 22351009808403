import * as React from 'react'
import './Panel.scss'

type Props = {
  title?: string
  buttonText?: string
  children?: React.ReactNode
  titleIcon?: string
}

const Panel = ({ title, buttonText, children , titleIcon }: Props) => {
  return (
    <div className="panel">
      <div className="panel__header">
        <h3 className="panel__title">
            {titleIcon && <span className={`${titleIcon} text-xl text-dark-100`}></span>}
            {title}
        </h3>
        {buttonText && <button className="panel__button">{buttonText}</button>}
      </div>
      <div className="panel__body">{children}</div>
    </div>
  )
}

export default Panel
