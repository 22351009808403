import React, { FC } from 'react'

const EmptyState: FC<{
    title: string
    subtitle: string
    icon: string
    actionText?: string
    cb?: () => void
}> = ({ title, subtitle, icon, actionText , cb }) => {

    return (
            <div className="flex flex-col justify-center items-center gap-y-3  py-12 px-5">
                <span className={`${icon} text-8xl text-s-gray-500`}></span>
                <h3 className="text-xl font-bold text-dark-300 dark:text-dark-200">{title}</h3>
                <p className="text-dark-200 font-normal text-xs dark:text-dark-100 whitespace-nowrap">{subtitle}</p>
                {actionText && <button onClick={cb} className="py-2 px-3.5 rounded-xl text-xs font-medium text-center align-middle whitespace-nowrap bg-secondary-50 bg-none border border-none text-primary-l hover:bg-secondary-d">
                    {actionText}
                </button>}
            </div>
    )
}

export default EmptyState
