import React, {FC, ButtonHTMLAttributes} from 'react'

interface AddButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
}

const AddButton: FC<AddButtonProps> = ({children, ...props}) => {
    return (
        <button
            {...props}
            className={`flex items-center justify-start gap-x-2 bg-secondary-50 text-sm border-secondary-50 hover:bg-secondary transition-colors text-primary-l px-4 py-2 rounded-3xl font-medium ${props.className}`} >
            <span className="sicon-add text-xl"></span>
            {children}
        </button>
    )
}
export default AddButton