import React, { FC } from 'react';
import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';

type Option = {
  label: string;
  value: string;
  id: string;
};

type Props = {
  title: string;
  subtitle?: string;
  options: Option[];
  isVertical?: boolean;
  value: string;
  setValue: (value: string) => void;
};
const RadioInput: FC<Props> = ({ title, subtitle, options, isVertical, setValue, value }) => {
  return (
    <RadioGroup value={value} onChange={setValue}>
      <RadioGroup.Label className="text-dark-300 flex items-start dark:text-dark-200 font-medium text-xs">
        {title}
      </RadioGroup.Label>
      <span className="text-dark-100 text-xxxs font-normal whitespace-nowrap my-2 flex items-start">
        {subtitle}
      </span>
      <div className={`mt-1 flex ${isVertical ? 'flex-col items-start' : 'items-center'} ms-1`}>
        {options.map(option => (
          <>
            <RadioGroup.Option
              key={option.value}
              value={option.value}
              className="relative flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none mt-1"
            >
              {({ checked }) => (
                <>
                  <RadioGroup.Label as="span" className="sr-only">
                    {title}
                  </RadioGroup.Label>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      checked ? 'bg-primary-l' : 'bg-white',
                      "w-3 h-3 ring-1 ring-primary-l ring-offset-2 rounded-full"
                  )}
                  />
                  <RadioGroup.Description
                    as="span"
                    className="text-dark-300 dark:text-dark-100 text-xs font-normal whitespace-nowrap ms-3 flex flex-col items-start"
                  >
                    {option.label}
                  </RadioGroup.Description>
                </>
              )}
            </RadioGroup.Option>
          </>
        ))}
      </div>
    </RadioGroup>
  );
};
export default RadioInput;
